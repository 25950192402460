<template>
  <div class="infomation main_top">
    <div class="help_content">
      <div class="help_content_item">
        <div class="help_content_item_question">1.1 {{ $t("key433") }}</div>
        <div class="help_content_item_answer">
          <p>(1) {{ $t("key434") }}</p>
          <p>(2) {{ $t("key435") }}</p>
          <img
            src="https://c2c.fp.guinfra.com/file/64b4bdd649acf1a157344e84rQfYVB7b03"
            style="width: 80%"
          />
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">1.2 {{ $t("key436") }}</div>
        <div class="help_content_item_answer">
          <p>{{ $t("key437") }}</p>
          <img
            src="https://c2c.fp.guinfra.com/file/6391f6cc9e15b76ae9f40b77WM3KzqMt03"
            style="width: 80%"
          />
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">1.3 {{ $t("key438") }}</div>
        <div class="help_content_item_answer">
          <p>(1) {{ $t("key439") }}</p>
          <p>(2) {{ $t("key440") }}</p>
          <p>(3) {{ $t("key441") }}</p>
          <p>(4) {{ $t("key442") }}</p>
          <p>{{ $t("key443") }}</p>
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">2.1 {{ $t("key444") }}</div>
        <div class="help_content_item_answer">
          <p>{{ $t("key445") }}</p>
          <img
            src="https://c2c.fp.guinfra.com/file/642695bde0bff55452484484WC0rPAiW03"
            style="width: 80%"
          />
          <p>{{ $t("key446") }}</p>
          <p>
            <img
              src="https://c2c.fp.guinfra.com/file/642695dbe0bff54d97a0c5a1x87DRdMm03"
              style="width: 80%"
            />
          </p>
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">2.2 {{ $t("key447") }}</div>
        <div class="help_content_item_answer">
          <p>1.{{ $t("key448") }}</p>
          <p>{{ $t("key449") }}</p>
          <p>2.{{ $t("key450") }}</p>
          <p>{{ $t("key451") }}</p>
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">2.3 {{ $t('key452') }}</div>
        <div class="help_content_item_answer">
          <p>
            {{ $t('key453') }}
          </p>
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">
          2.4{{ $t('key454') }}
        </div>
        <div class="help_content_item_answer">
          <p>
            {{ $t('key455') }}
          </p>
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">
          2.5 {{ $t('key456') }}
        </div>
        <div class="help_content_item_answer">
          <p>
            {{ $t('key457') }}
          </p>
          <p>{{ $t('key458') }}</p>
          <p>
            {{ $t('key459') }}
          </p>
          <p>
            {{ $t('key460') }}
          </p>
          <p>
            {{ $t('key461') }}
          </p>
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">
          2.6 {{ $t('key462') }}
        </div>
        <div class="help_content_item_answer">
          <p>{{ $t('key463') }}</p>
          <p>
            {{ $t('key464') }}
          </p>
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">
          3.1 {{ $t('key465') }}
        </div>
        <div class="help_content_item_answer">
          <p>
            {{ $t('key466') }}
          </p>
          <p>
            {{ $t('key467') }}
          </p>
          <p>
            {{ $t('key468') }}
          </p>
          <p>
            {{ $t('key469') }}
          </p>
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">
          3.2 {{ $t('key470') }}
        </div>
        <div class="help_content_item_answer">
          <p>
            {{ $t('key471') }}
          </p>
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">
          3.3{{ $t('key472') }}
        </div>
        <div class="help_content_item_answer">
          <p>
            {{ $t('key473') }}
          </p>
          <p><br /></p>
          <p>
            {{ $t('key474') }}
          </p>
          <p>
            {{ $t('key475') }}
          </p>
          <p>{{ $t('key476') }}</p>
          <p>{{ $t('key477') }}</p>
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">
          3.4 {{ $t('key478') }}
        </div>
        <div class="help_content_item_answer">
          <p>
            {{ $t('key479') }}
          </p>
          <p>1、{{ $t('key480') }}</p>
          <p>2、{{ $t('key481') }}</p>
          <p>
            3、{{ $t('key482') }}
          </p>
          <p>4、{{ $t('key483') }}</p>
          <p>
            5、{{ $t('key484') }}
          </p>
          <img
            src="https://c2c.fp.guinfra.com/file/63edf823cc777c06871b1218XhqZGd3x03"
            style="width: 80%"
          />
          <p><br /></p>
          <p>
            {{ $t('key485') }}
          </p>
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">
          4.1{{ $t('key486') }}
        </div>
        <div class="help_content_item_answer">
          <p>
            {{ $t('key487') }}
          </p>
          <p>
            {{ $t('key488') }}
          </p>
          <p>
            {{ $t('key489') }}
          </p>
          <p>
            (1) {{ $t('key490') }}
          </p>
          <p>(2) {{ $t('key491') }}</p>
          <p>{{ $t('key492') }}</p>
          <p>
            {{ $t('key493') }}
          </p>
          <p>
            {{ $t('key494') }}
          </p>
          <p>
            {{ $t('key495') }}
          </p>
          <p>
            {{ $t('key496') }}
          </p>
          <p>
            {{ $t('key497') }}
          </p>
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">
          4.2 {{ $t('key498') }}
        </div>
        <div class="help_content_item_answer">
          <p>
            {{ $t('key499') }}
          </p>
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">
          4.3 {{ $t('key500') }}
        </div>
        <div class="help_content_item_answer">
          <p>
            {{ $t('key501') }}
          </p>
          <p>{{ $t('key502') }}</p>
          <p>
            {{ $t('key503') }}
          </p>
          <p>
            {{ $t('key504') }}
          </p>
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">
          4.4 {{ $t('key505') }}
        </div>
        <div class="help_content_item_answer">
          <p>
            {{ $t('key506') }}
          </p>
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">
          4.5 {{ $t('key507') }}
        </div>
        <div class="help_content_item_answer">
          <p>
            {{ $t('key508') }}
          </p>
          <p>
            {{ $t('key509') }}
          </p>
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">
          4.6 T{{ $t('key510') }}
        </div>
        <div class="help_content_item_answer">
          <p>
            {{ $t('key511') }}
          </p>
          <p>
            {{ $t('key512') }}
          </p>
          <p>
            {{ $t('key513') }}
          </p>
          <p>I) {{ $t('key514') }}</p>
          <p>II) {{ $t('key515') }}</p>
          <p>
            {{ $t('key516') }}
          </p>
          <p>
            {{ $t('key517') }}
          </p>
          <p>
            (1) {{ $t('key518') }}
          </p>
          <p>
            (2) {{ $t('key519') }}
          </p>
          <p>(1){{ $t('key520') }}</p>
          <p>(2) {{ $t('key521') }}</p>
          <p>(3) {{ $t('key522') }}</p>
          <p>(4) {{ $t('key523') }}</p>
          <p>
            {{ $t('key524') }}
          </p>
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">
          5.1 {{ $t('key525') }}
        </div>
        <div class="help_content_item_answer">
          <p>
            {{ $t('key526') }}
          </p>
          <p><br /></p>
          <p>
            {{ $t('key527') }}
          </p>
          <p><br /></p>
          <p>
            {{ $t('key528') }}
          </p>
          <br />
          <p>
            (1) {{ $t('key529') }}
          </p>
          <p>
            (2) {{ $t('key530') }}
          </p>
          <p>
            (3) {{ $t('key531') }}
          </p>
          <p>
            (4) {{ $t('key532') }}
          </p>
          <br />
          <p>
            {{ $t('key533') }}
          </p>
          <br />
          <p>
            {{ $t('key534') }}
          </p>
          <p><br /></p>
          <p>
            {{ $t('key535') }}
          </p>
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">
          5.2 {{ $t('key536') }}
        </div>
        <div class="help_content_item_answer">
          <p>
            {{ $t('key537') }}
          </p>
          <p><br /></p>
          <p>
            {{ $t('key538') }}
          </p>
          <p><br /></p>
          <p>
            {{ $t('key539') }}
          </p>
          <p>(1) {{ $t('key540') }}</p>
          <p>
            (2) {{ $t('key541') }}
          </p>
          <p>
            (3) {{ $t('key542') }}
          </p>
          <p>
            (4) {{ $t('key543') }}
          </p>
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">
          5.3 {{ $t('key544') }}
        </div>
        <div class="help_content_item_answer">
          <p>
            {{ $t('key545') }}
          </p>
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">
          5.4 {{ $t('key546') }}
        </div>
        <div class="help_content_item_answer">
          <p>
            {{ $t('key547') }}
          </p>
          <p>
            (1) {{ $t('key548') }}
          </p>
          <p>(2) {{ $t('key549') }}</p>
          <p><br /></p>
          <p>
            {{ $t('key550') }}
          </p>
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">
          5.5 {{ $t('key551') }}
        </div>
        <div class="help_content_item_answer">
          <p>{{ $t('key552') }}</p>
          <p>{{ $t('key553') }}</p>
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">
          5.6 {{ $t('key554') }}
        </div>
        <div class="help_content_item_answer">
          <p>{{ $t('key555') }}</p>
          <p>{{ $t('key556') }}</p>
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">
          5.7 {{ $t('key557') }}
        </div>
        <div class="help_content_item_answer">
          <p>{{ $t('key558') }}</p>
          <p>
            {{ $t('key559') }}
          </p>
          <p>
            {{ $t('key560') }}
          </p>
          <p>{{ $t('key492') }}</p>
          <p>
            {{ $t('key561') }}
          </p>
          <p>
            {{ $t('key562') }}
          </p>
          <p>
            {{ $t('key563') }}
          </p>
        </div>
      </div>
      <div class="help_content_item">
        <div class="help_content_item_question">
          5.8 {{ $t('key564') }}
        </div>
        <div class="help_content_item_answer">
          <p>
            (1) {{ $t('key565') }}
          </p>
          <p>
            {{ $t('key566') }}
          </p>
          <p><br /></p>
          <p>
            (2) {{ $t('key567') }}
          </p>
          <p>
            {{ $t('key568') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>